import React, { useEffect } from 'react';
import { getItem, setItem } from 'helpers/storageHelper';
import { StaticQuery, graphql } from 'gatsby';
import EmbedFormModal from '../../EmbedFormModal';

const OnTapSubscribe: React.FC<any> = ({ handleClose }: any) => {
  useEffect(() => {
    const success = getItem('on-tap-subscribed');
    if (success === 'true') {
      handleClose();
    }
  }, []);

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.data.type === 'hsFormCallback' && event.data.eventName === 'onFormSubmitted') {
        setItem('on-tap-subscribed', 'true');
        handleClose();
      }
    });
  }, []);

  return (
    <EmbedFormModal
      Hubspot_Form_ID="c779fb40-9f2a-4861-9f20-d77cfd5a085b"
      title="Subscribe & Access"
      subtitle="Welcome to WellNet On Tap! <br> Subscribe for free & download an entire resource library.  <br> Sign up once & come back anytime for new content."
    />
  );
};

const OnTapSubscribeModal: React.FC<any> = ({ handleClose }: any) => {
  return (
    <StaticQuery
      query={graphql`
        query {
          cms {
            globalContent {
              data {
                attributes {
                  Subscribe_Popup_Hubspot_Form_Id
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <OnTapSubscribe
          formId={data?.cms?.globalContent?.data?.attributes?.Subscribe_Popup_Hubspot_Form_Id}
          handleClose={handleClose}
        />
      )}
    />
  );
};
export default OnTapSubscribeModal;
