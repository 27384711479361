export const CONFIG = {
  CMS_URL: process.env.CMS_URL || 'https://cms.wellnet.com',
  WWW_URL: process.env.WWW_URL || 'https://wellnet.com',
};

export const HUBSPOT = {
  API_BASE_URL:
    process.env.HSFORMS_API_URL || 'https://api.hsforms.com/submissions/v3/integration/submit',
  PORTAL_ID: process.env.HSFORMS_PORTAL_ID || '21829153',
};

export const PATHS = {
  INDEX: '/',
};

export const SOCIAL = {
  CONTACT: 'mailto:info@wellnet.com',
  FACEBOOK: 'https://www.facebook.com/WellNetHealth/',
  INSTAGRAM: 'https://www.instagram.com/wellnethealth/',
  LINKEDIN: 'https://www.linkedin.com/company/wellnet-healthcare/',
  TWITTER: 'https://twitter.com/WellNet_WN',
  YOUTUBE: 'https://www.youtube.com/c/####',
};
