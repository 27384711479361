import React from 'react';

import {
  ModalContainer,
  ModalTitle,
  ModalSubtitle,
  CloseIcon,
  LottieContainer,
} from './FormSuccessModal.styles';
import { IEmbedFormModal } from './FormSuccessModal.types';
import checkAnimation from './assets/check-success.json';
import { LottiePlayer } from '@components/LottiePlayer';
import closeIcon from './assets/circle-x.svg';

const FormSuccessModal: React.FC<any> = ({ handleClose }: IEmbedFormModal) => {
  return (
    <ModalContainer>
      <CloseIcon onClick={handleClose} src={closeIcon} />
      <LottieContainer>
        <LottiePlayer
          containerStyle={{ position: 'absolute', top: '-100px', width: '100%' }}
          animationData={checkAnimation}
          play
          restartLottie={false}
          autoplay
        />
      </LottieContainer>
      <ModalTitle>Thank you!</ModalTitle>
      <ModalSubtitle>
        Your submission has been successfully received. We will be in touch within 24 hours.
      </ModalSubtitle>
    </ModalContainer>
  );
};
export default FormSuccessModal;
